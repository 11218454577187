@import url('./fonts.css');

body {
  margin: 0;
  padding: 0;
  font-family: "Muller", sans-serif !important;
}

button, input, optgroup, select, textarea, h4 {
  font-family: "Muller", sans-serif !important;
}

