.coupon-card {
  margin-left: 5% !important;
  width: 90% !important;
  box-shadow: none !important;
}
.coupon-apply-input{
  width: 130px !important;
  height: 30px !important;
  overflow: hidden !important;
  border-radius: 6px !important;
  display: flex;
  flex-grow: 1;
  font-size: 13px !important;
}
.coupon-card-content{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0 !important;
  margin-left: 5px !important;
}
.coupon-card-button-confirm{
  width: 60% !important;
  margin-left: 10px !important;
  border-radius: 8px !important;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2) !important;
  color:#FFFFFF !important;
  background: linear-gradient(to right,#d81524,#871c1c) !important;
  font-size: 16px !important;
  font-family: 'Muller', monospace !important;
  height: 30px !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}