@font-face {
    font-family: 'Muller';
    src: local('Muller Regular'), local('Muller-Regular'),
    url('../../fonts/MullerRegular.woff2') format('woff2'),
    url('../../fonts/MullerRegular.woff') format('woff'),
    url('../../fonts/MullerRegular.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
}
#container{
    overflow:hidden;
}
#text{
    font-family: 'Muller', monospace;
    font-size: 13px;
    color: #000000;
    text-align: center;
}

#container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    margin: auto;
    height: 85vh;
}
#socialWebs{
    display: flex;
    gap: 15px;
}