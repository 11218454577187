.modal-payment {
    position: static!important;
    width: 95%!important;
    height: 95%!important;
    max-width: 480px!important;
    max-height: 650px!important;
}

div.content__back.ng-star-inserted{
    display: none!important;
}