.scan-view {
    display: flex;
    flex-direction: column;
    align-items: center;

}

.scan-selector {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 19px;
}

.scan-selector-item {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 13px;
    line-height: 16px;
    text-transform: uppercase;
    cursor: pointer;
    width: 195px;
    min-height: 43px;
    border-bottom-right-radius: 10px;
}

.scan-text {
    text-align: center;
    font-size: 13px;
    line-height: 20px;
    max-width: 331px;
    width: 100%;
    margin-bottom: 26px;
}

.scan-icon-container {
    position: relative;
    background-color: #B9B9B9;
    width: 390px;
    min-height: 436px;
}

.scan-photo-icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.scan-btn {
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    margin-top: 52px !important;
    width: 294px;
    height: 54px;
    background-image: linear-gradient(to right, #D81524,#871C1C) !important;
    color: white !important;
    border-radius: 10px !important;
    border: none;
}

.scant-btn:hover {
    opacity: 0.7;
}

.scan-btn-text {
    font-size: 25px;
    line-height: 16px;
    font-weight: 700;
    flex: 1;
    text-align: center;
}

.scan-export-icon {
    margin-left: auto;
}


