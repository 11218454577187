@media  (min-width: 445px) {
    .button-event{
        font-size: 15px !important;
    }
}

@media (max-width: 444px) {
    .button-event {
        font-size: 13.5px !important;
    }
}

@media (max-width: 403px) {
    .button-event {
        font-size: 13px !important;
    }
}

@media (max-width: 425px) {
    .text-for-date {
        font-size: 13.5px !important;
        white-space: wrap!important;
    }
}

@media (max-width: 406px) {
    .text-for-date {
        font-size: 13.5px !important;
    }
}

@media (max-width: 370px) {
    .text-for-date {
        font-size: 13px !important;
    }
}

@media (max-width: 356px) {
    .text-for-date {
        font-size: 12.5px !important;
    }
}

@media (max-width: 346px) {
    .text-for-date {
        font-size: 12px !important;
    }
}







.main-container-one {
    display: flex !important;
    flex-direction: column !important;
    gap: 5px !important;
    padding: 0 !important;
    border-radius: 10px !important;
}

.text-date-event {
    display: flex;
    gap: 15px;
    margin: 0 10px 0 10px;
}

.text-for-date {
    color: #FF0000;
    font-size: 15px;
    white-space: nowrap;
}

.question-write {
    border: 1px transparent!important;
}

.text-about-event {
    margin-left: 10px;
    margin-top: 10px;
    font-size: 14px;
    color: #000000;
}

.container-buttons-one {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 7px;
    margin-right: 10px;
    margin-left: 8px;
}

.button-event {
    color: white !important;
    font-family: 'Muller-Bold', monospace !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    border-radius: 10px !important;
    background: linear-gradient(to right, #D81524, #871C1C) !important;
    height: 39px;
    width: 80%;
    border: 1px transparent !important;
    font-weight: 700 !important;

}

.text-rate-forum {
    display: flex;
    justify-content: center;
    font-family: 'Muller-Bold', monospace;
    font-weight: 700;
    color: #E40C0C;
    font-size: 20px;
}

.rate-forum {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 7px;
}

.speaker-list {
    margin-left: 10px;
}

.speakers {
    font-family: 'Muller-Bold', monospace;
    font-weight: 700;
    font-size: 16px;
    color: #000000;
}

.circle-user {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 36px;
    height: 36px;
    background-color: #D9D9D9;
    border-radius: 50%;
}


.container-user-icon {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 7px;
}

.text-for-speaker {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.text-about-speaker {
    font-size: 14px;
    line-height: 16px;
}

.accordion-speakers {
    font-family: 'Muller', monospace !important;
}
.question-online-container {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.text-question-online {
    margin-left: 10px;
    font-family: Muller, monospace;
    font-size: 16px;
    font-weight: 700;
    line-height: 16px;
}

.box-question {
    margin-bottom: 0.15em;
    margin-left: 10px;
    margin-right: 10px;
    box-shadow: 0px 0px 10px -5.5px black;
    border-radius: 10px;
    /*background-color: #FFE7E7;*/
    background-color: white;
    padding: 8px 8px 10px 15px;
}

.box-of-questions {
    display: flex;
    flex-direction: column;
    gap: 9px;
}

.text-question {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.text-question-popup {
    white-space: normal;
    overflow-wrap: break-word;
}

.name-speaker-text {
    font-family: 'Muller-Bold', monospace ;
    font-weight: 700;
    font-size: 13px;
    line-height: 16px;
}

.current-date-text {
    float: right;
    font-weight: 100;
    font-size: 10px;
}


.title {
    font-family: 'Muller', monospace !important;
    color: #000000 !important;
}

.container-send-question {
    display: flex;
    justify-content: center;
}

.send-question {
    color: white !important;
    font-family: 'Muller-Bold', monospace !important;
    font-size: 15px !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    border-radius: 10px !important;
    background: linear-gradient(to right, #D81524, #871C1C) !important;
    height: 39px;
    border: 1px transparent !important;
    font-weight: 700 !important;
}

.popup-text {
    margin: 3px 0 0 0 ;
}

.question-popup-button {
    position: relative;
    background: none;
    border: none;
    bottom: 15px;
    left: 15px;
    float: right;
}

.photo-for-speaker {
    width: 55px;
    border-radius: 50%
}