.support-view {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.support-text {
    font-size: 13px;
    line-height: 20px;
    text-align: center;
    margin-bottom: 12px;
}

.support-icon {
    margin-bottom: 33px;
}

.support-form {
    width: 98%;
    margin-bottom: 12px;
}

.support-text-area {
    font-size: 10px;
    line-height: 13px;
    width: 100%;
    min-height: 90px;
    box-shadow: rgba(0, 0, 0, 0.24) 0 3px 8px !important;
    border: none !important;
    border-radius: 10px;
    resize: none !important;
}

.support-btn {
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    width: 183px;
    height: 39px;
    background-image: linear-gradient(to right, #D81524,#871C1C) !important;
    color: white !important;
    border-radius: 10px !important;
}

.support-btn:hover {
    opacity: 0.7;
}
