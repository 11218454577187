@font-face {
    font-family: 'Muller-Bold';
    src: url('../../fonts/muller-bold.eot'); /* IE 9 Compatibility Mode */
    src: url('../../fonts/muller-bold.eot?#iefix') format('embedded-opentype'), /* IE < 9 */
    url('../../fonts/muller-bold.woff2') format('woff2'), /* Super Modern Browsers */
    url('../../fonts/muller-bold.woff') format('woff'), /* Firefox >= 3.6, any other modern browser */
    url('../../fonts/muller-bold.ttf') format('truetype'); /* Safari, Android, iOS */
    font-style: normal;
    font-weight: bold;
}

@font-face {
    font-family: 'Muller';
    src: local('Muller Regular'), local('Muller-Regular'),
    url('../../fonts/MullerRegular.woff2') format('woff2'),
    url('../../fonts/MullerRegular.woff') format('woff'),
    url('../../fonts/MullerRegular.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;

}

@media screen and (max-width: 768px) {
    .container-cards1 {
        flex-direction: row; /* изменение направления flex при уменьшении экрана */
        gap: 20px; /* увеличение промежутка между блоками */
        align-items: center;
        display: flex;
    }

    .container-ticket1 {
        align-items: stretch;
        gap: 5px;
        width: auto;
        height: auto;
    }

    .main-container1 {
        display: flex;
        align-items: stretch;  /* чтобы блоки растягивались на всю ширину */
        width: auto;
        margin: 0px 10px 0px 10px;
    }

    .label-for-icon1 {
        width: 100%; /* ширина занимает всю доступную ширину */
        text-align: center; /* центрирование текста по центру блока */
        margin-right: 2.5%;
    }

    .main-block1 {
        display: flex;
        flex-direction: column;
    }
}


.arrow-icon1 {
    float: right;
    display: flex;
    justify-content: right;
    margin-right: 2em;
}

.icontab1 {
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 54px;
    height: 54px;
    border-radius: 10px;
    background-color: #FFE7E7;
    box-shadow: 0px 0px 10px -5.5px black;
    margin-left: 0.8%;
}

.container-cards1 {
    display: flex;
    flex-direction: row;
    gap: 12px;
    width: 100%;
    align-items: flex-start;
}

.main-block1 {
    display: flex;
    flex-direction: column;
}

.main-container1 {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: calc(100% - 10px);
    align-items: center; /* чтобы выровнять по центру по горизонтали */
    justify-content: center;
    margin-top: 4px;
}

.label-for-icon1 {
    display: flex;
    background-color: #FFFFFF;
    border-radius: 10px;
    box-shadow: 0px 0px 10px -6px black;
    width: 100%;
    height: 54px;
    border: 1px solid transparent;
    align-items: center; /* чтобы выровнять текст по центру вертикально */
}

.text-for-icon1 {
    color: black;
    padding: 1em;
    font-size: 1em;
    font-family: 'Muller', 'Muller Regular', monospace;
    text-align: justify;
}

