@font-face {
    font-family: 'Muller';
    src: local('Muller Regular'), local('Muller-Regular'),
    url('../../fonts/MullerRegular.woff2') format('woff2'),
    url('../../fonts/MullerRegular.woff') format('woff'),
    url('../../fonts/MullerRegular.ttf') format('truetype');
    font-style: normal;
}
.checkout-step-confirm-text-asiaexpo{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    line-height: 2;
    font-size: 13px;
}
.checkout-step-confirm-header{
    font-family: 'Muller', monospace !important;
    font-size: 16px !important;
    margin-top: 0 !important;
}