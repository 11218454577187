html, body {
    margin: 0;
    padding: 0;
    height: 100%;
    overflow-x: hidden;
}

.container_exp {
    font-family: "Muller", serif;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    border-radius: 10px;
    width: 96%;
    left: 2%;
    position: relative;
    padding: 20px;
    box-sizing: border-box;
    min-height: 100vh;
    overflow-y: auto;
}


.card_exp {
    position: relative;
    display: flex;
    flex-direction: row;
    padding: 16px;
    margin: 12px 0;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.24) 0 3px 8px;
    width: 100%;
}


.image_exp {
    width: 105px;
    height: 105px;
    margin-right: 16px;
}


.info_exp {
    flex: 1;
    padding-left: 5px;
}


.text_exp {
    margin-top: 23px;
    margin-bottom: 25px;
    display: flex;
    flex-direction: row;
}


.name_exp {
    cursor: pointer;
    font-family: 'MullerBold', serif;
    font-size: 20px;
    font-weight: bold;
    margin-top: -20px;
    margin-left: -7px;
}

.location_exp {
    font-family: "MullerBold", serif;
    font-size: 17px;
    color: rgba(237, 28, 36, 1);
    font-weight: bold;
    margin: 5px 5px 5px 2px;
}

.description_exp {
    font-family: "Muller", sans-serif;
    font-size: 14px;
    color: #000000;
    margin-left: -114px;
    padding-top: 73px;
    hyphens: auto;
}


.flag_exp {
    /*position: absolute;*/
    /*top: 24px;*/
    /*right: 24px;*/
    cursor: pointer;
}

.flag-icon_exp {
    width: 24px;
    height: 24px;
    cursor: pointer;
}


.geo-point_exp {
    width: 23px;
    height: 23px;
    margin: 1px 1px 1px -10px;
}

.flag-exp .bookmark {
    box-shadow: none!important;
}


.country_Russia_exp {
    width: 34px!important;
    height: 34px;
    margin-top: -3px;
    margin-left: 15px;
    padding-top: 8px!important;
    padding-left: 5px!important;
}


.search_exp {
    width: 100%;
    border: none;
    border-bottom: 2px solid white;
    color: #f9f9f9;
    padding: 10px;
    font-size: 16px;
    outline: none;
    font-family: 'Muller', serif;
    background: linear-gradient(90deg, #FF0000 0%, #FF5959 0.01%, #E10404 100%);
}

.container.exponents {
    margin-left: 10px;
}



@media (max-width: 500px) {
    .name_exp {
        font-size: 16.5px;
    }
}


.more-container {
    display: flex;
    align-items: center;
    flex-direction: row;
    float: right;
}

.more-arrow {
    margin-top: 17px;
    margin-left: 3px;
    float: right;
    cursor: pointer;
}

.text-more-exp {
    float: right;
    font-size: 13px;
    color: red;
    padding-top: 10px;
    cursor: pointer;
}
