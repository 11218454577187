.modal-exposition {
    border-radius: 12px!important;
}

.modal-exposition-load {
    border-radius: 12px!important;
    min-height: 270px;
}

.img-modal-exponent {
    height: 150px;
}

.modal-exposition-content  {
    border-radius: 12px!important;

}

.container-data-exponent {
    display: flex!important;
    flex-direction: row!important;
    padding-top: 15px!important;

}

.text-modal-exponent {
    display: flex!important;
    flex-direction: column!important;
    padding-left: 24px!important;
    gap: 10px!important;
    margin: auto 0!important;
    
}

.subject-modal-exponent {
    font-size: 18px!important;
    font-weight: 700!important;
    hyphens: auto!important;
    word-break: break-all;
}

.desc-modal-exponent {
    font-size: 15px!important;
    hyphens: auto!important;
    word-break: break-word;

}

.text-more-exp-exposition {
    float: right!important;
    font-size: 13px!important;
    color: red!important;
    padding-top: 10px!important;
    padding-bottom: 10px!important;
    cursor: pointer!important;
}

.img-modal-exponent {
    width: 150px!important;
    height: 150px!important;
}