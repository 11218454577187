.notify-page-message-req {
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
}

.notify-page-message {
    /*border-radius: 10px;*/
    /*box-shadow: 0 0 15px -10px black;*/
    /*padding: 10px;*/
    display: flex;
    flex-direction: column;
    align-items: start;
}

.write-message-placeholder {
    margin-top: 8px;
    font-size: 15px;
    line-height: 10px;
    border-bottom: 0.5px solid #D9D9D9;
    max-width: 200px;
}

.answer-button {
    color: white !important;
    font-family: 'Muller-Bold', monospace !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    border-radius: 10px !important;
    background: linear-gradient(to right, #D81524, #871C1C) !important;
    height: 39px;
    width: 40%;
    border: 1px transparent !important;
    font-weight: 700 !important;
    margin-top: 15px!important;
}
