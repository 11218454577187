@font-face {
    font-family: 'Muller';
    src: local('Muller Regular'), local('Muller-Regular'),
    url('../../fonts/MullerRegular.woff2') format('woff2'),
    url('../../fonts/MullerRegular.woff') format('woff'),
    url('../../fonts/MullerRegular.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'MullerBold';
    src: url('../../fonts/muller-bold.eot'); /* IE 9 Compatibility Mode */
    src: url('../../fonts/muller-bold.eot?#iefix') format('embedded-opentype'), /* IE < 9 */
    url('../../fonts/muller-bold.woff2') format('woff2'), /* Super Modern Browsers */
    url('../../fonts/muller-bold.woff') format('woff'), /* Firefox >= 3.6, any other modern browser */
    url('../../fonts/muller-bold.ttf') format('truetype'), /* Safari, Android, iOS */
    url('../../fonts/muller-bold.svg#muller-bold') format('svg'); /* Chrome < 4, Legacy iOS */
}

@media (max-width: 373px) {
    .picture-congress {
        width: 100%;
        height: 50%;
        object-fit: cover;
    }
}

.container {
    margin-left: 11px;
    margin-right: 11px;
}

.main-text {
    margin-top: 11px;
    text-align: justify;
    font-size: 13px;
    font-family: 'Muller', 'Muller Regular', monospace;
}

.title-text {
    color: #FF0000;
    font-weight: bold;
    font-size: 24px;
    font-family: 'MullerBold', monospace;
}

.subtitle-text {
    color: #FF0000;
    font-weight: bold;
    font-size: 13px;
    font-family: 'MullerBold', 'Muller Regular', monospace;
}

.pictures-congress {
    margin-top: 8px;
    min-width: 0px;
    min-height: 0px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    gap: 15px;
}

.picture-congress {
    width: 168px;
    max-height: 79px;
    object-fit: cover;
    border-radius: 10px;
    box-shadow: 0px 0px 10px -4px black;
}

@media (max-width: 380px) {
    .picture-congress {
        width: 140px;
    }
}

.ymaps-2-1-79-gototech {
    display: none!important;
}

#event-time {
    margin-top: 0px;
}

.ymap {
    margin-top: 10px;
}

#event-date {
    margin-top: 10px;
    margin-bottom: 10px;
}

#cong-title {
    margin-top: 1%;
}

#university {
    margin: 0;
    overflow-wrap: break-word;
    text-align: start;
}

.view-map {
    display: flex!important;
    justify-content: center!important;
    align-items: center!important;
    flex-direction: column;
    margin-top: 10%;
    gap: 10px;
}

.view-images {
    display: flex!important;
    justify-content: center!important;
    align-items: center!important;
    flex-direction: column;
    margin-top: 5%;
    gap: 10px;
}