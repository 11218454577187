.exponent-view {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.exponent-btn {
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    width: 183px;
    height: 39px;
    background-image: linear-gradient(to right, #D81524,#871C1C) !important;
    color: white !important;
    border-radius: 10px !important;
    margin-top: 22px !important;
}

.exponent-info {
    max-width: 371px;
    width: 100%
}

.exponent-card {
    margin-top: 15px;
}

.exponent-text-container {
    display: flex;
    justify-content: space-between;
    margin-bottom: 8px;
}

.exponent-icon-delete {
    cursor: pointer;
}

.exponent-icon-delete:hover {
    opacity: 0.5;
}

.exponent-card-name {
    font-size: 15px;
    color: #ff0000;
    line-height: 20px;
}

.exponent-card-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    min-height: 63px;
    background-color: #fff;
    box-shadow: rgba(0, 0, 0, 0.24) 0 3px 8px;
    border-radius: 10px;
}

.exponent-card-position {
    font-size: 13px;
    line-height: 20px;
    margin-left: 17px;
    margin-bottom: 11px;
}

.exponent-contacts {
    display: flex;
    margin-left: 17px;
}

.exponent-contacts-container {
    display: flex;
}

.exponent-contacts-container:last-of-type {
    margin-left: 20px;
}

.exponent-contact-text {
    font-size: 13px;
    line-height: 20px;
    margin-left: 9px;
}

.exponent-popup {
    position: relative;
    max-width: 350px;
    width: 100%;
    height: 375px;
}

.exponent-popup-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 327px !important;
    width: 100% !important;
}

.exponent-popup-close {
    position: absolute;
    top: 8px;
    right: 11px;
    cursor: pointer;
}

.exponent-popup-field {
    max-width: 327px;
    width: 100%;
    margin-bottom: 10px;
}

.exponent-popup-field:last-of-type {
    margin: 0 !important;
}

.exponent-popup-label {
    font-size: 15px !important;
    line-height: 20px !important;
    font-weight: 700 !important;
    color: #ff0000 !important;
    margin-bottom: 7px !important;

}

.exponent-popup-input input {
    height: 20px;
    box-shadow: rgba(0, 0, 0, 0.1) 0 0 5px !important;
    border: none !important;
    border-radius: 10px !important;
    margin-bottom: 10px !important;
    font-size: 8px !important;
    text-align: left !important;
}

.exponent-popup-btn {
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    width: 183px;
    height: 39px;
    background-image: linear-gradient(to right, #D81524,#871C1C) !important;
    color: white !important;
    border-radius: 10px !important;
    margin-top: 22px !important;
}
