@font-face {
  font-family: 'Muller';
  src: local('Muller Regular'), local('Muller-Regular'),
  url('../../fonts/MullerRegular.woff2') format('woff2'),
  url('../../fonts/MullerRegular.woff') format('woff'),
  url('../../fonts/MullerRegular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}
.button-goto-payment-options,
.checkout-address-buttons-button {
  width: 90% !important;
}

.checkout-steps-group {
  width: 90% !important;
  margin-left: 5% !important;
}

.checkout-steps-icon {
  font-size: 24px !important;
}

.checkout-steps-description {
  font-size: 12px !important;
}

.checkout-step {
  padding: 10px !important;
}
.checkout-address-buttons-button{
  width: 230px !important;
  padding: 10px !important;
  border-radius: 10px !important;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2) !important;
  color:#FFFFFF !important;
  background: linear-gradient(to right,#d81524,#871c1c) !important;
  font-size: 16px !important;
  font-family: 'Muller', monospace !important;
}

.checkout-address-buttons-button-disabled {
  width: 230px !important;
  padding: 10px !important;
  border-radius: 10px !important;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2) !important;
  color:#FFFFFF !important;
  opacity: 50% !important;
  background: linear-gradient(to right,#d81524,#871c1c) !important;
  font-size: 16px !important;
  font-family: 'Muller', monospace !important;
}