.profile-info {
    display: flex;
    justify-content: flex-start;
    align-content: center;
    min-height: 78px;
}

.profile-icon-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 78px;
    height: 78px;
    border-radius: 50%;
    background-color: #D9D9D9;
    margin-left: 10px;
}

.profile-icon {
    width: 57px;
    height: 57px;
}

.profile-avatar {
    width: 78px;
    height: 78px;
    border-radius: 50%;
    margin-left: 10px;
}

.profile-info-container {
    margin-left: 18px;
}

.profile-info-text {
    display: flex;
    align-items: center;
    font-size: 15px;
    line-height: 20px;
}

.profile-name {
    font-weight: 700;
}

.profile-text-container {
    display: flex;
    align-items: center;
    margin-right: 15px;
    margin-bottom: 3px;
}

.profile-edit-text {
    min-height: 10px;
    font-size: 11px;
    line-height: 10px;
    cursor: pointer;
    border-bottom: 0.5px solid #D9D9D9;
}

.profile-edit-text-ru {
    width: 145px;
}

.profile-edit-text-en {
    width: 80px;
}

.profile-edit-text:hover {
    opacity: 0.5;
}

.profile-edit-icon {
    margin-left: 4px;
}

.profile-contact-icon {
    margin-right: 5px;
}

.profile-nav {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 24px;
}

.profile-btn-container {
    display: flex;
    width: 98%;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 11px;
}

.profile-nav-icon {
    max-width: 54px;
    width: 100%;
    height: 54px;
    border-radius: 10px;
    background-color: #FFE7E7;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.profile-nav-icon:hover {
    opacity: 0.6;
}

.profile-btn {
    width: 100%;
    min-height: 54px !important;
    background-color: #FFF !important;
    border: none !important;
    border-radius: 10px !important;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px !important;
    color: #000 !important;
    text-align: start !important;
    padding: 1em !important;
    font-size: 1em !important;
    font-weight: 400 !important;
    cursor: pointer;
    margin: 0 0 0 12px !important;
}

.profile-btn:hover {
    opacity: 0.6;
}

.profile-popup-lang {
    max-width: 150px;
    width: 100%;
}

.profile-popup-lang-content {
    display: flex !important;
    flex-direction: column !important;
    align-items: center !important;
    justify-content: center !important;
    height: 66px;

}

.profile-popup-lang-btn {
    display: flex !important;
    align-items: center !important;
    font-weight: 400 !important;
    background: none !important;
    color: #000 !important;
    padding: 0 !important;
    width: 101px;
    text-align: left;
}

.profile-popup-lang-btn:hover {
    opacity: 0.6;
}

.profile-popup-lang-icon {
    width: 24px;
    height: 24px;
    margin-right: 9px;
}

.profile-popup-lang-text {
    font-size: 12px;
    line-height: 16px;
}

.profile-popup-lang-divider {
    width: 142px;
    margin-top: 7.5px !important;
    margin-bottom: 7.5px !important;
}

.profile-social-container {
    max-width: 138px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-top: 30px;
}

.profile-popup-rating {
    max-width: 371px;
    width: 100%;
    min-height: 107px;
    padding: 0 0 0 0 !important;
    border-radius: 10px !important;
    background-color: #FFF !important;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px !important;
}

.profile-popup-rating-form {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.profile-close-icon {
    width: 6px;
    height: 6px;
    position: absolute;
    top: 0;
    right: 0;
    cursor: pointer;
}

.profile-popup-rating-field {
    max-width: 350px;
    width: 100%;
}

.profile-popup-rating-content {
    padding: 7px 10.5px 9px !important;
    border-radius: 10px !important;
}

.profile-popup-rating-stars {
    margin-bottom: 22px;
}

.profile-popup-rating-btn {
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    width: 183px;
    height: 39px;
    background-image: linear-gradient(to right, #D81524,#871C1C) !important;
    color: white !important;
    border-radius: 10px !important;
}

.ui.rating .active.icon {
    color: red;
}

.ui.rating .selected.icon {
    color: red !important;
    opacity: 0.5 !important;
}

.ui.rating .hovered.icon {
    color: red;
}

.ui.rating .icon {
    background: 0 0;
    color: #BFBFBF;
}

@media screen and (max-width: 850px){
    .profile-btn-container {
        width: 93%;
    }
}
