.auther-location-edit-button {
    position: absolute;
    right: 1em;
    top: 1em;
}
#html5-qrcode-button-camera-stop,
#html5-qrcode-button-camera-permission,
#html5-qrcode-button-camera-start {
    height: 2em;
    border: 1px;
    color: transparent;
    font-size: 1rem;
    font-weight: 700;
    cursor: pointer;
    display: inline-block;
    min-height: 1em;
    outline: 0;
    border: none;
    vertical-align: baseline;
    background: #e0e1e2 none;
    font-family: Lato,'Helvetica Neue',Arial,Helvetica,sans-serif;
    margin: 0 0.25em 1rem 0;
    padding: 8px 0;
    text-transform: none;
    text-shadow: none;
    line-height: 1em;
    font-style: normal;
    text-align: center;
    text-decoration: none;
    border-radius: 0.28571429rem;
    box-shadow: 0 0 0 0 rgba(34,36,38,.15) inset;
    background-image: linear-gradient(to right, #D81524,#871C1C) !important;
    content: '';
}


.html5-camera-en #html5-qrcode-button-camera-stop{
    color: white;
}

.html5-camera-en #html5-qrcode-button-camera-permission {
    color: white;
}
.html5-camera-en #html5-qrcode-button-camera-start {
    color: white;
}
.html5-camera-ru > #html5qr-code-full-region__header_message::before {
    content: "Идет запрос разрешения камеры. Пожалуйста, подождите...";
    color:white;
}

.html5-camera-ru #html5-qrcode-button-camera-stop:before {
    content: 'Остановить сканирование ';
    color: white;
}


.html5-camera-ru #html5-qrcode-button-camera-permission:before {
    content: 'Разрешить доступ к камере ';
    color: white;
}

.html5-camera-ru #html5-qrcode-button-camera-start:before {
    content: 'Начать сканирование ';
    color: white;
}

#html5-qrcode-button-camera-stop{
    width: 200px;
}
#html5-qrcode-button-camera-permission {
    width: 200px;
}
#html5-qrcode-button-camera-start {
    width: 180px;
}


#html5qr-code-full-region img[alt="Info icon"] {
    display: none;
}
#html5-qrcode-anchor-scan-type-change {
    display:none!important;
}
#html5qr-code-full-region__dashboard_section_csr {
    width: auto;
}
#html5qr-code-full-region__dashboard_section_csr > span:first-of-type {
    color: transparent;
}
#html5-qrcode-select-camera:before {
    content: "Выберите камеру";
    color: black;
}
#html5-qrcode-select-camera {
    min-height: 1em;
    outline: 0;
    border: none;
    vertical-align: baseline;
    background: #e0e1e2 none;
    font-family: Lato,'Helvetica Neue',Arial,Helvetica,sans-serif;
    margin: 0 0.25em 1rem 0;
    padding: 4px;
    text-transform: none;
    text-shadow: none;
    line-height: 1em;
    font-style: normal;
    text-align: center;
    text-decoration: none;
    border-radius: 0.28571429rem;
    box-shadow: 0 0 0 0 rgba(34,36,38,.15) inset;
}

#html5qr-code-full-region__scan_region {
    min-height: 300px !important;
    display: flex;
    align-items: center;
    justify-content: center;
}

.check-ticket-order-number {
    font-weight: 700;
}
.scanner-reader,
.scanner-header {
    margin: 0.5em!important;
}
#html5qr-code-full-region__scan_region video {
    padding: 3% 15%;
}

#qr-shaded-region::after {
    content: '';
    background: white;
    width: 0.25em;
    height: 90%;
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    margin: auto;
    opacity: 7;
    z-index: 2;
    animation: 4s infinite ease-in-out roll;
}

.scan-popup {
    max-width: 500px;
    width: 100%;
    border-radius: 10px !important;
    position: relative;
}

.scan-close {
    position: absolute;
    top: 10px;
    right: 10px;
    z-index: 1;
    width: 15px;
    height: 15px;
    cursor: pointer;
}

:root {
    --left-start-pos: -100px;
    --right-stop-pos: 100px;
}

@keyframes roll {
    0% {
        transform: translateX(var(--left-start-pos));
    }
    50% {
        transform: translateX(var(--right-stop-pos));
    }
    100% {
        transform: translateX(var(--left-start-pos));
    }
}
