@font-face {
    font-family: 'Muller';
    src: local('Muller Regular'), local('Muller-Regular'),
    url('../../fonts/MullerRegular.woff2') format('woff2'),
    url('../../fonts/MullerRegular.woff') format('woff'),
    url('../../fonts/MullerRegular.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'MullerBold';
    src: url('../../fonts/muller-bold.eot'); /* IE 9 Compatibility Mode */
    src: url('../../fonts/muller-bold.eot?#iefix') format('embedded-opentype'), /* IE < 9 */
    url('../../fonts/muller-bold.woff2') format('woff2'), /* Super Modern Browsers */
    url('../../fonts/muller-bold.woff') format('woff'), /* Firefox >= 3.6, any other modern browser */
    url('../../fonts/muller-bold.ttf') format('truetype'), /* Safari, Android, iOS */
    url('../../fonts/muller-bold.svg#muller-bold') format('svg'); /* Chrome < 4, Legacy iOS */
}

@media (max-width: 398px) {
    .bookmark-text {
        font-size: 13px;
    }
}

@media (max-width: 350px) {
    .bookmark-text {
        font-size: 11px;
    }
}

@media (min-width: 398px) {
    .bookmark-text {
        font-size: 13.5px;
    }

}

#bookmark-tab-program.active {
    width: 100%;
    height: 43px;
    max-width: 195px;
    background-color: #fff;
    border-radius: 0px 0px 10px 0px;
    border: 0px solid;
    box-shadow: 4px 4px 15px -10px black;
    margin-top: 0px;
    margin-right: 0px;
    font-family: 'Muller';
    color: #FC0000;
    font-weight: 300;
}

#bookmark-tab-program {
    width: 100%;
    height: 43px;
    max-width: 195px;
    background: none;
    border-radius: 0px 0px 10px 0px;
    border: 0px solid;
    margin-top: 0px;
    margin-right: 0px;
    font-family: 'Muller';
    color: #606060;
    font-weight: 300;

}


#bookmark-tab-exhibits.active {
    width: 100%;
    height: 43px;
    max-width: 195px;
    background-color: #fff;
    border-radius: 0px 0px 0px 10px;
    border: 0px solid;
    box-shadow: 4px 4px 15px -10px black;
    margin-top: 0px;
    margin-right: 0px;
    font-family: 'Muller';
    color: #FC0000;
    font-weight: 300;
}

#bookmark-tab-exhibits {
    width: 100%;
    height: 43px;
    max-width: 195px;
    background: none;
    border: 0px solid;
    margin: 0px 0px 0px 0px;
    font-family: 'Muller';
    color: #606060;
    font-weight: 300;
}

.tab-buttons {
    display: flex;
    justify-content: space-between;
}

.bookmark {
    margin: 12px 0px 0px 0px ;
    box-shadow: 0px 1px 15px -7px black;
    width: 100%;
    border-radius: 10px;
}

.bookmark-text {
    font-family: Muller;
    font-weight: 300;
    line-height: 17px;
}
.bookmark-text-red {
    font-family: Muller;
    text-align: justify;
    line-height: 17px;
    color: red;
    font-weight: 800;
    margin: 3px 15px 0px 0px
}

.bookmark-text-b{
    font-family: Muller;
    font-weight: 700;
    text-align: justify;
    line-height: 17px;
    font-size: 20px;
}

.bookmark-text-span {
    font-family: Muller;
    font-weight: 300;
    text-align: justify;
    line-height: 17px;
    font-size: 10px;
    color: #FC0000;
}

.bookmark-topbar {
    width: 100%;
    height: 48px;
    background: linear-gradient(to right, #D81524, #871C1C);
    border-radius: 10px;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.bookmark-topbar-exhibits {
    width: 40px;
    height: 40px;
    background: none;
    margin: 2px 0 0;
    display: flex;
    align-items: center;
    float: right;
    padding-left: 8px;
}

.bookmark-line {
    width: 0px;
    height: 20.5px;
    border-left: 0.6px solid black;
    margin: 0 4% 0 4%;
    text-align: justify;

}

.bookmark-inside {
    display: flex;
    align-items: center;
    margin-right: 15px;
    position: relative;
    /*justify-content: space-between;*/
}

.bookmark-inside-all {
    margin-left: 1%;

}

.bookmark-inside-exhibits {
    display: flex;
    align-items: center;
    margin-left: 5px ;
}

.bookmark-icon {
    margin-left: 5px;
    float: right;
    border: 0px solid !important;
    background: none !important;
    padding: 3px 6px 0 0 !important;
}

#bookmark-view {
    display: flex;
    width: 10px;
    height: 16px;
    float: right;
    margin: 0px 0px 0px 0px;
    align-items: center;
    justify-content: center;
    background: none;
}

.bookmark-exhibits {
    margin: 12px 0 0 0;
    box-shadow: 0px 1px 15px -7px black;
    width: 100%;
    height: 164px;
    border-radius: 10px;
}

.green-agriculture-logo {
    width: 100%;
    height: 100%;
    max-width: 104px;
    max-height: 104px;
    margin-top: 15px;
}

.bookmark-container {
    display: flex;
    align-items: center;
    flex-direction: column;
    margin: 0px 2% 0px 2%;
}

.viewbutton {
    margin: 2% 0 0 0;
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    width: 100%;
    max-width: 73px;
    float: right;
}

#bookmark-name {
    margin-left: 11px;
}

.bookmark-inside-text {
    margin: 2% 2% 0 10px;
}

.bookmark-arrow {
    margin: 9px 10px 0px 0px;
    float: right;
}

.calendar-icon {
    margin: 0px 10px 0px 20px;
}

#bookmark-time {
    color: white;
}

.exhibits-inside-text {
    display: flex;
    flex-wrap: wrap;
    gap: 4px;
    align-items: center;
    margin-left: 10px;
    max-width: 195px;
}

.company-name {
    margin: 0 0 0 13px;
}

.box-one {
    display: flex;
    align-items: center;
}

.box-two {
    display: flex;
    align-items: center;
}

.box-two-archive {
    display: flex;
    align-items: center;
    padding-right: 10px;
}

.no-have-bookmarks {
    margin: 20px 0 0 0 !important;
    font-size: 15px;
}

.view-bookmarks {
    display: flex!important;
    justify-content: center!important;
    align-items: center!important;
    flex-direction: column;
    margin-top: 25%;
    gap: 7px;
}

.text-bookmark {
    font-size: 13px;
    text-align: center;
}
