.company-view {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.company-header {
    display: flex;
}

.company-title {
    font-size: 20px;
    line-height: 24px;
    font-weight: 700;
    padding-top: 18px;
    margin-left: 4px;
}

.company-logo-container {
    position: relative;
    max-width: 105px;
    width: 100%;
    min-height: 105px;
    display: flex;
    align-items: center;
    justify-content: center;

}

.company-edit-icon {
    width: 17px;
    height: 17px;
    position: absolute;
    top: 18px;
    right: 13px;
    cursor: pointer;
}

.company-edit-none {
    position: unset;
    margin-left: 4px
}

.company-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 3px;
    max-width: 380px !important;
    width: 100% !important;
}

.company-formfield {
    max-width: 380px !important;
    width: 100% !important;

}

.company-label {
    color: #ff0000 !important;
    font-size: 15px !important;
    line-height: 20px !important;
    font-weight: 700 !important;
    margin-bottom: 7px;
}

.company-text-area {
    font-size: 8px !important;
    min-height: 74px;
    box-shadow: rgba(0, 0, 0, 0.1) 0 0 5px !important;
    border: none !important;
    border-radius: 10px;
    resize: none !important;
}

.company-input-container {
    position: relative;
    display: flex;
    align-items: center;
}

.company-input input {
    height: 20px;
    box-shadow: rgba(0, 0, 0, 0.1) 0 0 5px !important;
    border: none !important;
    border-radius: 10px !important;
    margin-bottom: 10px !important;
    font-size: 8px !important;
}

.company-btn {
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    width: 100% !important;
    height: 20px;
    background-color: #D61523 !important;
    color: white !important;
    border-radius: 10px !important;
    font-size: 8px !important;
}

.company-btn:hover {
    opacity: 0.6;
}

.company-plus-icon {
    position: absolute;
    top: 6px;
    right: 5px;
    cursor: pointer;
}
