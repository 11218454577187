.sidebar {
  padding-top: 20px;
  z-index: 199;
}
.ui.sidebar.menu-second-side-bar  {
  height: auto!important;
  right: 150px!important;
  z-index: 10!important;
}
.item-second-menu{
  text-align: left!important;
}
.header-divider-menu{
  margin: 0!important;
}
.field-second-menu{
  display: flex;
  align-items: center;
}
.icon-second-menu{
  margin-right: 0.5em;
}
.header-second-menu{
  font-weight: 900!important;
}