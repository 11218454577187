@font-face {
    font-family: 'Muller';
    font-weight: 400;
    font-display: swap;
    src: local('Muller-Regular'),
        url('fonts/MullerRegular.woff2') format('woff2'),
        url('fonts/MullerRegular.woff') format('woff'),
        url('fonts/MullerRegular.ttf') format('truetype');
}

@font-face {
    font-family: 'Muller';
    font-weight: 700;
    font-display: swap;
    src: local('Muller-Bold'),
        url("fonts/muller-bold.woff2"),
        url("fonts/muller-bold.woff"),
        url("fonts/muller-bold.ttf");
}
