#language-modal-button{
    display: block!important;
    margin: 20px auto!important;
    font-size: 14px!important;
    background: linear-gradient(to right, #D81524, #871C1C)!important;
    color: #FFFFFF!important;
    font-family: "Muller", monospace !important;
    border-radius: 10px!important;
    text-align: center!important;
    width: 200px!important;
}

#language-modal-title-text{
    color:transparent!important;
    background-clip: text!important;
    background-image: linear-gradient(to right, #D81524, #871C1C)!important;
    font-weight: bold!important;
    font-size: 20px!important;
    text-align: center!important;
    margin-top: 12px!important;

}

#language-modal{
    border-radius: 15px !important;
}

.language-modal-content {
    border-radius: 15px !important;
}

.close-icon-modal-language {
    float: right!important;
    margin-bottom: 10px!important;
    cursor: pointer!important;
}