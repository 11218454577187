@font-face {
    font-family: 'Muller';
    src: local('Muller Regular'), local('Muller-Regular'),
    url('../../fonts/MullerRegular.woff2') format('woff2'),
    url('../../fonts/MullerRegular.woff') format('woff'),
    url('../../fonts/MullerRegular.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Muller-Bold';
    src: url('../../fonts/muller-bold.eot'); /* IE 9 Compatibility Mode */
    src: url('../../fonts/muller-bold.eot?#iefix') format('embedded-opentype'), /* IE < 9 */
    url('../../fonts/muller-bold.woff2') format('woff2'), /* Super Modern Browsers */
    url('../../fonts/muller-bold.woff') format('woff'), /* Firefox >= 3.6, any other modern browser */
    url('../../fonts/muller-bold.ttf') format('truetype'); /* Safari, Android, iOS */
    font-style: normal;
    font-weight: bold;
}

.main-block3 {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 25px;
}

.container-buttons {
    display: flex;
    flex-direction: column;
    gap: 5px;
    width: 50%;
    max-width: 65vw;
}

.text-for-label {
    font-family: 'Muller';
    color: black;
    font-size: 14px;
    text-align: center;

}

#input-num {
    border-radius: 10px;
    width: 100%;
    height: 54px;
    font-family: 'Muller';
    background: #EBE7E5;
    color: #777777;
    font-size: 14px;
}

.input-numbers {
    border-radius: 10px;
    width: 100%;
    height: 54px;
    font-family: 'Muller';
    background: #EBE7E5;
    color: #777777;
    font-size: 14px;
}

.button-confirm-code {
    border-radius: 10px;
    background: linear-gradient(to right, #D81524, #871C1C);
    height: 45px;

    margin-left: -5%;

    display: flex;
    justify-content: center;
}

.text-for-link {
    color: white;
    font-family: 'Muller-Bold', monospace;
    font-size: 14px;

    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    background: linear-gradient(to right, #D81524, #871C1C);
    height: 45px;
    width: 110%;
    margin-left: -5%;
    margin-right: -10%;
    border: 1px transparent;
    font-weight: 700;
}

.text-for-link-disabled {
    color: white;
    font-family: 'Muller-Bold', monospace;
    font-weight: 700;
    font-size: 14px;

    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    height: 45px;
    width: 110%;
    margin-left: -5%;
    margin-right: -10%;
    border: 1px transparent;
    background: darkgrey;

}

.main-block3 {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 25px;
}

.container-buttons {
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.text-for-label {
    font-family: 'Muller';
    color: black;
    font-size: 14px;
    text-align: center;
}

#input-num {
    border-radius: 10px;
    width: 100%;
    height: 54px;
    font-family: 'Muller';
    background: #EBE7E5;
    color: #777777;
    font-size: 14px;
}

#input-numbers {
    border-radius: 10px;
    width: 100%;
    height: 54px;
    font-family: 'Muller';
    color: #777777;
    font-size: 14px;
}

.button1 {
    border-radius: 10px;
    background: linear-gradient(to right, #D81524, #871C1C);
    height: 45px;
    width: 110%;
    margin-top: 15px;
    margin-left: -5%;
    margin-right: -10%;
    display: flex;
    justify-content: center;
}

.text-for-link {
    color: white;
    font-family: 'Muller-Bold', monospace;
    font-size: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    background: linear-gradient(to right, #D81524, #871C1C);
    height: 45px;
    width: 110%;
    margin-left: -5%;
    margin-right: -10%;
    border: 1px transparent;
    font-weight: 700;
}

.text-for-link-disabled {
    color: white;
    font-family: 'Muller-Bold', monospace;
    font-weight: 700;
    font-size: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    height: 45px;
    width: 110%;
    margin-left: -5%;
    margin-right: -10%;
    border: 1px transparent;
    background: darkgrey;
}



.container-inputsss {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 61%;
    max-width: 65vw;
    margin-left: 2.5%;
}



.input-number12 {
    border-radius: 10px!important;
    width: 100% !important;
    height: 54px!important;
    background: #EBE7E5!important;
    color: #777777!important;
    font-size: 14px!important;
}

.container-text-registration {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 37px;
}

.main-block-registration {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 25px;
    height: 100%;
    padding-top: 15px;
    padding-bottom: 15px;
}

.container-buttons {
    display: flex;
    flex-direction: column;
    gap: 5px;
    width: 90%;
}

.container-inputss {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 90%;
}

.container-inputs-registration {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 10px;
    width: 90%;
}

.text-for-label {
    font-family: 'Muller';
    color: black;
    font-size: 14px;
    text-align: center;
}

.input-number12 {
    border-radius: 10px!important;
    width: 100% !important;
    height: 54px!important;
    background: #EBE7E5!important;
    color: #777777!important;
    font-size: 14px!important;
}

.button2 {
    border-radius: 10px;
    background: linear-gradient(to right, #D81524, #871C1C);
    height: 45px;
    display: flex;
    justify-content: center;
}

.text-for-link-registration {
    color: white;
    font-family: 'Muller-Bold', monospace;
    font-size: 14px;
    text-align: justify;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    background: linear-gradient(to right, #D81524, #871C1C);
    height: 45px;
    width: 110%;
    font-weight: 700;
    border: 1px transparent;
}

.text-for-link-registration-disabled {
    color: white;
    font-family: 'Muller-Bold', monospace;
    font-size: 14px;
    text-align: justify;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    font-weight: 700;
    height: 45px;
    width: 110%;
    border: 1px transparent;
    background: darkgrey;
}

.main-block5 {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 25px;
    margin-top: 40px;
    margin-bottom: 40px;
}

.text-for-label {
    font-family: 'Muller';
    color: black;
    font-size: 14px;
    text-align: center;
}

.container-inputs {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    gap: 25px;
}

.container-numbers {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 15px;
}

.number-phone {
    font-family: 'Muller-Bold';
    color: #FF0A19;
    font-size: 16px;
    height: 16px;
}

#btnCode {
    height: 14px;
    background: none;
    border: 1px transparent;
}

.input-number {
    height: 54px;
    width: 60px;
    border-radius: 12px;
    text-align: center;
    color: #000000;
    font-family: 'Muller-Bold';
    font-size: 32px;
    border: 0px solid;
    background: #EBE7E5;
    font-weight: 700;
}

.text-again {
    font-family: 'Muller';
    font-size: 14px;
    color: #A7AFB7;
    text-align: center;
    cursor: pointer;
}

.container-again-button {
    display: flex;
    justify-content: center;
    align-items: center;

}