@font-face {
  font-family: 'Muller';
  src: local('Muller Regular'), local('Muller-Regular'),
  url('../../fonts/MullerRegular.woff2') format('woff2'),
  url('../../fonts/MullerRegular.woff') format('woff'),
  url('../../fonts/MullerRegular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}
.card-summary-row {
  padding-top: 5px !important;
  padding-bottom: 5px !important;
  font-size:13px;
  font-family: "Muller",monospace;
}
.card-summary{
  margin-top:0 !important;
  width: 90% !important;
  border-radius: 10px !important;
}
