.asiameet-view {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.asiameet-selector {
    display: flex;
    justify-content: space-between;
    width: 99%;
}


.asiameet-faq-container {
    width: 94%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 5px;
}

.asiameet-faq {
    margin-top: 15px;
    height: 32px;
    width: 100%;
    position: relative;
    flex-direction: row;
    font-weight: 700;
    background-color: #FFFFFF;
    border-radius: 10px;
    font-size: 13px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
    padding-left: 15px;
    padding-top: 7px;
    cursor: pointer;
}

.asiameet-faq-content {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 98%;
    min-height: 120px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    border-radius: 0 0 10px 10px ;
    margin-bottom: 11px;
}

.asiameet-faq-text {
    padding-top: 15px;
    text-align: center;
    margin-bottom: 16px;
    font-size: 14px;
    line-height: 16px;
}

.asiameet-faq-btn {
    position: absolute;
    bottom: 10px;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    width: 183px;
    height: 39px;
    background-image: linear-gradient(to right, #D81524,#871C1C) !important;
    color: white !important;
    border-radius: 10px !important;
    border: none;
    cursor: pointer;
}

.asiameet-faq-btn:hover {
    opacity: 0.7;
}

.asiameet-vector {
    display: -webkit-box !important;
    margin-left: 49%;
    width: 93%;
    margin-top: -12px;
}

.asiameet-selector-item {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 13px;
    line-height: 16px;
    text-transform: uppercase;
    cursor: pointer;
    width: 195px;
    min-height: 43px;
    border-bottom-right-radius: 10px;
}

.selector-active {
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    border-bottom-left-radius: 10px;
    color: #FC0000;
}

.asiameet-btn {
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    font-size: 14px !important;
    line-height: 16px !important;
    white-space: nowrap;
    width: 157px;
    height: 31px;
    background-image: linear-gradient(to right, #D81524,#871C1C) !important;
    color: white !important;
    border-radius: 10px !important;
    margin-bottom: 24px !important;
}

.asiameet-btn:hover {
    opacity: 0.7;
}

.asiameet-content {
    width: 750px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.asiameet-card {
    position: relative;
    width: 100%;
    min-height: 200px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    border-radius: 0 0 10px 10px ;
    margin-bottom: 11px;
}

.asiameet-card-planned {
    position: relative;
    width: 100%;
    min-height: 200px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    border-radius: 0 0 10px 10px ;
    margin-bottom: 11px;
}

.asiameet-card-time {
    display: flex;
    align-items: center;
    width: 100%;
    min-height: 48px;
    background-image: linear-gradient(to right, #D81524,#871C1C);
    border-radius: 10px;
}

.asiameet-card-time-container {
    display: flex;
    align-items: center;
    min-height: 22px;
    margin-left: 23px;
}

.asiameet-card-time-text {
    color: #fff;
    font-size: 13px;
    line-height: 16px;
    font-weight: 700;
    margin-left: 12px;
}

.asiameet-card-container {
    display: flex;
    flex-direction: column;
    margin-top: 11px;
}


.asiameet-card-text-container {
    text-align: left;
    margin-left: 23px;
    margin-bottom: 16px;
}

.asiameet-card-text {
    font-size: 13px;
    line-height: 16px;
    margin-bottom: 6px;
}

.asiameet-card-text:last-of-type {
    margin-bottom: 0;
}

.asiameet-card-btn-container {
    display: flex;
    justify-content: center;
    margin-top: 15px;
}

.asiameet-card-btn {
    display: flex !important;
    align-items: center !important;
    border: none;
    cursor: pointer;
    justify-content: center !important;
    font-size: 14px !important;
    line-height: 16px !important;
    width: 157px;
    height: 31px;
    color: white !important;
    border-radius: 10px !important;
    margin: 0 !important;
    margin-right: 5px !important;
}

.asiameet-card-btn:hover {
    opacity: 0.7;
}

.asiameet-card-empty {
    font-size: 25px;
    margin: 0 auto;
    text-align: center;
}

.asiameet-checkbox-container {
    display: flex;
    align-items: center;
}

.asiameet-checkbox-text {
    font-size: 14px;
    line-height: 16px;
    padding-top: 15px;
    text-align: center;
    margin-right: 5px;
}

.red-checkbox .ui.toggle.checkbox input:checked~.box:before, .ui.toggle.checkbox input:checked~label:before {
    background-image: linear-gradient(to right, #D81524,#871C1C) !important;
}

@media screen and (max-width: 750px) {
    .asiameet-content {
        width: 96%;
        margin: auto;
    }

    .asiameet-vector {
        margin-left: 45%;
    }

    .asiameet-faq-content {
        min-height: 160px;
    }

}
