.header-bookmark-text {
    color: white;

    padding-top: 4px;
}

.header-bookmark-text.bookmark-date {
    margin-right: 12px;
}

#rating-bookmark {
    display: flex;
    float: right !important
}

#bookmark-tab-24-october.active {
    width: 100%;
    height: 43px;
    max-width: 195px;
    background-color: #fff;
    border-radius: 0px 0px 10px 10px;
    border: 0px solid;
    box-shadow: 4px 4px 15px -10px black;
    margin-top: 0px;
    margin-right: 0px;
    font-family: 'Muller';
    color: #FC0000;
    font-weight: 300;
}

#bookmark-tab-24-october {
    width: 100%;
    height: 43px;
    max-width: 195px;
    background: none;
    border: 0px solid;
    margin: 0px 0px 0px 0px;
    font-family: 'Muller';
    color: #606060;
    font-weight: 300;
}

.forum-name {
    padding-right: 3%;
    /*border-right: 0.6px solid black;*/
    /*min-width: 200px;*/
    /*width: calc(450px - (450px - 183px) * ((100vw - 320px) / (800 - 320)));*/
    min-width: calc(183px + (450 - 183) * ((100vw - 320px) / (920 - 320)));
    max-width: calc(183px + (450 - 183) * ((100vw - 320px) / (920 - 320)));
}

@media (min-width: 800px) {
    .forum-name {
        max-width: 400px;
        min-width: 400px;
    }

}

.dividing-line {
    border-right: 0.6px solid black;
    /*width: 15px;*/
    min-height: inherit;
    height: 25px;
    /*rotate: 90deg;*/
    /*align-self: stretch;*/
    margin: 0 auto 0 0;
    position: static;
    inset: 0;
    right: 200px;
}


.hall-name {
    /*padding-left: calc(5px + 80 * ((100vw - 320px) / (1980 - 60)));*/
    padding-left: 5%;
}

@media (max-width: 400px) {
    .hall-name {
        padding-left: 5%;
    }

    .header-bookmark-text.bookmark-date {
        font-size: 11.5px;
    }

    .header-bookmark-text.bookmark-time {
        font-size: 11.5px;
    }
}

@media (max-width: 345px) {
    .header-bookmark-text.bookmark-date {
        font-size: 11px;
    }

    .header-bookmark-text.bookmark-time {
        font-size: 11px;
    }
}

@media (max-width: 540px) {
    .dividing-line {
        margin: 0;
    }


}

