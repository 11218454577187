.vcard {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 500px;
    width: 100%;
    min-height: 300px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    border-radius: 0 0 10px 10px ;
    margin-bottom: 11px;
}

.vcard-header {
    display: flex;
    align-items: center;
    width: 100%;
    min-height: 48px;
    background-image: linear-gradient(to right, #D81524,#871C1C);
    border-radius: 10px;
}

.vcard-text-container {
    margin-top: 14px;
    text-align: left;

    margin-bottom: 16px;
}

.vcard-header-text {
    color: #fff;
    font-size: 16px;
    line-height: 16px;
    font-weight: 700;
    margin-left: 12px;
}

.vcard-text {
    font-size: 13px;
    line-height: 16px;
    margin: 0;
    margin-bottom: 5px;
}

.vcard-btn {
    position: absolute;
    bottom: 10px;
    margin: auto;
    display: flex !important;
    align-items: center !important;
    border: none;
    cursor: pointer;
    justify-content: center !important;
    font-size: 14px !important;
    line-height: 16px !important;
    width: 157px;
    height: 39px;
    background-image: linear-gradient(to right, #D81524,#871C1C) !important;
    color: white !important;
    border-radius: 10px !important;
}

