.meeting-card {
    position: relative;
    width: 98%;
    min-height: 200px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    border-radius: 0 0 10px 10px ;
    margin-bottom: 11px;
}

.meeting-card-time {
    display: flex;
    align-items: center;
    width: 100%;
    min-height: 48px;
    background-image: linear-gradient(to right, #D81524,#871C1C);
    border-radius: 10px;
}

.meeting-card-time-container {
    display: flex;
    align-items: center;
    min-height: 22px;
    margin-left: 23px;
}

.meeting-card-time-text {
    color: #fff;
    font-size: 13px;
    line-height: 16px;
    font-weight: 700;
    margin-left: 12px;
}

.meeting-card-container {
    display: flex;
    flex-direction: column;
    margin-top: 11px;
}

.meeting-card-text-container {
    text-align: left;
    margin-left: 23px;
    margin-bottom: 16px;
}

.meeting-card-btn-container {
    display: flex;
    justify-content: center;
    padding-bottom: 15px;
}

.meeting-card-btn {
    display: flex !important;
    align-items: center !important;
    border: none;
    cursor: pointer;
    justify-content: center !important;
    font-size: 14px !important;
    line-height: 16px !important;
    width: 157px;
    height: 31px;
    color: white !important;
    border-radius: 10px !important;
    margin: 0 !important;
    margin-right: 5px !important;
}

.card-btn-red {
    background-image: linear-gradient(to right, #D81524,#871C1C) !important;
}

.card-btn-gray {
    background-color: #C2C2C2 !important;
}
