.body_expo_card {

}

.image_expo_card {
    width: 105px;
    height: 105px;
    margin-top: -5px;
    margin-left: 11px;
}

.info_expo_card {
    flex: 1;
}

.text_expo_card {
    margin-top: -85px;
    margin-left: 158px;
    display: flex;
    flex-direction: row;
}

.country_Russia_exp.card-expo {
        width: 34px!important;
        height: 34px;
        margin-top: -3px;
        margin-left: 15px;
        padding-top: 8px!important;
    }

.name_expo_card {
    font-family: 'MullerBold', serif;
    font-size: 20px;
    margin-top: 5px;
    font-weight: bold;
    margin-left: 144px;
}

.location_expo_card {
    font-family: "MullerBold", serif;
    font-size: 17px;
    color: rgba(237, 28, 36, 1);
    font-weight: bold;
    margin: 5px 5px 5px 2px;
}

.description_expo_card {
    font-family: "Muller", sans-serif;
    font-size: 13px;
    margin-left: -114px;
    margin-top: 20px;
    color: #000000;
}

.flag_expo_card {
    top: 9px;
    right: 24px;
    cursor: pointer;
    position: absolute;
}

.flag-icon_expo_card {
    width: 24px;
    height: 24px;
    cursor: pointer;
}

.geo-point_expo_card {
    width: 23px;
    height: 23px;
    margin: 1px 1px 1px -10px;
}

.country_Russia_expo_card {
    width: 34px;
    height: 34px;
    margin-top: -3px;
    margin-left: 15px;
}

.company_info_expo_card {
    margin-left: 2%;
    width: 95%;
    padding-top: 35px;
    font-family: 'Muller', serif;;
    word-wrap: break-word;
}

.red_expo_card {
    font-family: 'MullerBold', serif;
    font-size: 24px;
    margin-top: 30px;
    margin-left: 10px;
    color: rgba(237, 28, 36, 1);
}

.people_name_expo_card {
    margin-bottom: 5px;
}

.about_expo_card {
    font-family: 'Muller', serif;
    flex-direction: row;
    margin-left: 115px;
    margin-top: -80px;
}

.ellipse_expo_card {
    margin-left: -70px;
    z-index: 1;
    position: relative;
    width: 83px;
    height: 83px;
}

.user_expo_card {
    margin-left: 10px;
    margin-bottom: 10px;
    background-color: #D9D9D9;
    z-index: 2;
    position: relative;
}

.photo_expo_card {
    margin-left: 15px;
    margin-top: 15px;
}

.phone_expo_card {
    margin-right: 7px;
}

.mail_expo_card {
    margin-top: 4px;
    margin-right: 7px;
}

.email_expo_card {
    margin-top: -22px;
    margin-left: 20px;
}

.stries_expo_card {
    margin-top: 15px;
    height: 32px;
    margin-left: 3%;
    width: 93%;
    position: relative;
    flex-direction: row;
    background-color: #FFFFFF;
    border-radius: 10px;
    font-family: 'Muller', serif;
    font-size: 13px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
    padding-left: 15px;
    padding-top: 7px;
    cursor: pointer;
}

.vector_expo_card {
    display: -webkit-box !important;
    margin-left: 45%;
    width: 93%;
    margin-top: -12px;
}

.underline_expo_card {
    margin-top: 9px;
    cursor: pointer;
}

.underline_expo_card_archive {
    margin-top: 9px;
}



.underline_expo_card {
    flex: 1;
}

.segments_expo_card {
    padding-left: 12px;
    width: 96%;
    font-family: 'Muller', serif;
    font-size: 15px;
    margin-top: 10px;
    display: flex;
    flex-direction: column;

}

.stries_expo_card span {
    color: black;
    text-decoration: none!important
}

.watch-icon {
    height: 14px!important;
    width: 14px!important;
    float: right;
    cursor: pointer;
}

.text-presentation {
    font-size: 12px;
}

.container-present {
    display: flex;
    flex-direction: row;
    gap: 8px;
    justify-content: space-between!important;
    align-items: center;
    padding-bottom: 2px;
    padding-top: 6px;
}

.container-present-not {
    display: flex;
    flex-direction: row;
    gap: 8px;
    justify-content: center;
    align-items: center;
    padding-bottom: 2px;
    padding-top: 6px;
}

.container-watch-presents {
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    width: 87%;
    margin-left: 6%;
    padding-left: 8px;
    padding-right: 8px;
}

.presentation-icons {
    display: flex;
    gap: 8px;
}

.view-presentations {
    display: flex!important;
    justify-content: center!important;
    align-items: center!important;
    flex-direction: column;
    margin-top: 2%;
    gap: 7px;
}

.categories-container {
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.categories-text-desc {
    font-size: 12px;
    color: #818181!important;
    padding-top: 5px;
}

.categories-text-desc-mobile {
    font-size: 11px;
    color: #818181!important;
    padding-top: 5px;
}

.category-name {
    font-size: 12px;
    color: #1E73BE !important;
    cursor: pointer;
    text-decoration: underline;
    text-decoration-color: red;
    text-decoration-skip-ink: none;
}

.dot {
    color: red;
}

.exponent-card-popup {
    max-width: 350px !important;
}
