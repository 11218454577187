@font-face {
    font-family: 'Muller';
    src: local('Muller Regular'), local('Muller-Regular'),
    url('../../fonts/MullerRegular.woff2') format('woff2'),
    url('../../fonts/MullerRegular.woff') format('woff'),
    url('../../fonts/MullerRegular.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
}
@font-face {
    font-family: 'MullerBold';
    src: url('../../fonts/muller-bold.eot'); /* IE 9 Compatibility Mode */
    src: url('../../fonts/muller-bold.eot?#iefix') format('embedded-opentype'), /* IE < 9 */
    url('../../fonts/muller-bold.woff2') format('woff2'), /* Super Modern Browsers */
    url('../../fonts/muller-bold.woff') format('woff'), /* Firefox >= 3.6, any other modern browser */
    url('../../fonts/muller-bold.ttf') format('truetype'), /* Safari, Android, iOS */
    url('../../fonts/muller-bold.svg#muller-bold') format('svg'); /* Chrome < 4, Legacy iOS */
}
.btns{
    display: flex;
    justify-content: center;
    align-content: center;
}
#btn{
    color:#FFFFFF;
    background:#D8D8D8;
    margin:3px;
    border-radius:5rem;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}
#btn.active{
    background: linear-gradient(to right,#d81524,#871c1c);
}
.main-text {
    margin-top: 11px;
    text-align: justify;
    font-size: 13px;
    font-family: 'Muller', 'Muller Regular', monospace;
}
.title-text {
    color: #FF0000;
    font-weight: bold;
    font-size: 24px;
    font-family: 'MullerBold', monospace;
    margin-bottom: 15px;
    margin-top: 25px;
    line-height: 1.15;
}
#icon{
    width:29px;
    height:29px;
    margin-top:2px;
}
#iconText{
    display: flex;
    flex-direction: row;
}
.contentText{
    display: flex;
    flex-direction: column;
}
.pictures{
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin-top:7px;
}
.picture{
    border-radius: 10px;
    width: 176px;
    height: auto;
}
.pictureGroup{
    display: flex;
    justify-content: center;
}
.icon-text{
    text-align: justify;
    font-size: 13px;
    font-family: 'Muller', 'Muller Regular', monospace;
    margin-left: 8px;

}
.pageText{
    margin-left: 0;
    margin-right: 7px;
}
#button-container {
    display: flex;
    flex-direction: column;
    align-content: center;
}
#asiaChampionShift{
    width: 75%;
    margin-left: 44px;
}
@media (min-width: 414px){
    #button-container {
        width:100%;
        margin-left:0;
    }
    #asiaChampionShift{
        margin-left:16px;
        width:90%;
    }
}
@media (min-width: 560px){
    #asiaChampionShift{
        margin-left:24px;
    }
}
@media (min-width: 690px){
    #asiaChampionShift{
        margin-left:40px;
    }
}