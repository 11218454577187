.modal-prompt {
    position: static!important;
    width: 50% !important;
}
.modal-prompt-field {
    font-size: 13px !important;
    width: 100%!important;
}

.modal-prompt-field-error {
    font-size: 10px;
    color: red;
    width: 100%;
}

.modal-yes-btn {
    background-image: linear-gradient(to right, #D81524,#871C1C) !important;
    color: white !important;
}

.modal-yes-btn:hover {
    opacity: 0.7;
}
