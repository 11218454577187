@font-face {
  font-family: 'Muller';
  src: local('Muller Regular'), local('Muller-Regular'),
  url('../../fonts/MullerRegular.woff2') format('woff2'),
  url('../../fonts/MullerRegular.woff') format('woff'),
  url('../../fonts/MullerRegular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}
.modal-order-edit-address {
  top: 1em!important;
  position: static!important;
}

.shipping-details-row, .shipping-field-cell {
  padding: 0 5px !important;
}

.shipping-field input,
.shipping-address-list .dropdown,
.shipping-field .dropdown {
  border-radius: unset !important;
  border-top: none !important;
  border-right: none !important;
  border-left: none !important;
  font-size: 13px !important;
  height: 30px!important;
}

.user-card-content{
  border-top: none !important;
}

.shipping-details-card, .delivery-details-card, .order-summary {
  width: 90% !important;
}

.shipping-details-card{
  box-shadow: none !important;
}
.shipping-address-list .ui.dropdown .menu > .item{
  font-size: 13px !important;
  font-family: 'Muller', monospace !important;
}
.shipping-address-list .ui.dropdown{
  font-size: 13px !important;
  font-family: 'Muller', monospace !important;
}
.user-card-header{
  font-size: 16px !important;
  font-family: 'Muller', monospace !important;
}

.shipping-details-grid {
  padding-top: 5px !important;
  padding-bottom: 5px !important;
  margin-left: -1.6rem !important;
  margin-right: -1.6rem !important;
}
.shipping-email{
  padding-top: 5px !important;
  height: 35px !important;
}
.shipping-details-row.hidden {
  display: none !important;
}

.shipping-field-error {
  font-size: 10px;
  color: red;
  text-align: center;
  width: 100%;
}

.react-dadata__suggestion-note {
  font-size: 12px !important;
}

.react-dadata__suggestion {
  font-size: 13px !important;
}

.react-dadata__input:focus, .shipping-field input:focus {
  box-shadow: none !important;
  border-color: #2185d0 !important;
}

.react-dadata__input {
  border: 1px solid rgba(34, 36, 38, .15) !important;
}

.react-dadata__suggestion-subtitle {
  font-size: 13px !important;
}

.phone-country-mask #dropdown_country {
  width: 60px!important;
}
.phone-country-mask #dropdown_country .menu.visible {
  width: 200px!important;
}
.phone-country-mask #dropdown_country div.divider.text,
.phone-country-mask #dropdown_country.ui.active.search.dropdown input.search:focus+.text {
  color: transparent!important;
  border: none!important;
}
.phone-country-mask #dropdown_country .dropdown-item {
  width: 100%/*max-content*/!important;
}
.shipping-address-list .dropdown .flag,
.phone-country-mask #dropdown_country .flag {
  margin-top: 4px !important;
}
.phone-country-mask #masked_phone {
  padding-left: 0!important;
  padding-top: 15px !important;
  height: 35px !important;
}
.phone-country-mask:focus-within {
  border-bottom: solid 1px #2185d0 !important;
}
.phone-country-mask #masked_phone:focus {
  border-color: transparent!important;
}

@media (max-width: 440px) {
  .column.shipping-field-cell {
    width: 100% !important;
  }
}