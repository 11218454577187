.shop-name {
  text-align: left;
  flex: 1;
  width: 110px;
  font-size: 11px!important;
}
.shop-name a:focus,
.shop-name a {
  color: white;
  font-size: 11px!important;
}
.shop-icon {
  color: white;
}

.header-title-text {
  display: -webkit-box!important;
  -webkit-line-clamp: 1!important;
  -webkit-box-orient: vertical!important;
  overflow: hidden;
  text-overflow: ellipsis !important;
  line-height: 1;
  word-break: break-all;
  max-width: 725px;
  height: 26px;
}

@media (max-width: 500px) {
  .header-title-text {
    height: 18px;
  }
}

.shop-text-item {
  font-family: 'Muller' !important;
  font-style: normal !important;
  font-weight: 300 !important;
  font-size: 10px !important;
  line-height: 14px !important;
  color: #000000 !important;
}
.cart-counter.ui.circular.label,
.task-counter.ui.circular.label{
  left: 80% !important;
  border: 1px solid !important;
  border-color:  white !important;
}
.shop-icon-item:focus,
.shop-icon-item,
.ui.secondary.inverted.menu a.item{
  color: white!important;
  font-size: small;
}
.ui.button.circular {
  color: white;
  background: transparent;
  margin: -0.5em 0 !important;
  font-size: 1.37rem !important;
}
.nav-top-bar .menu-search-item {
  width: calc(100% - 110px);
  position: relative;
}
.nav-top-bar .menu-search-item form {
  width: 100%!important;
  display: flex;
}
/*.nav-top-bar .input.search {*/
/*  width: calc(100% - 121px)!important;*/
/*}*/
.search-dropdown {
  min-width: 86px!important;
  background: white!important;
}
.search-dropdown .text{
  padding: 1em 0.25em;
}
.search-dropdown .dropdown.icon {
  float: right!important;
  padding: 16px 5px 0 0!important;
  margin: auto 0!important;
}
.input.search .search-button {
  border-bottom-left-radius: 0!important;
  border-top-left-radius: 0!important;
}
.input.search .search-field {
  border-bottom-right-radius: 0!important;
  border-top-right-radius: 0!important;
}
.input.search .search-field:active,
.input.search .search-field:focus{
  border-top: none!important;
  border-bottom: none!important;
  border-left: none!important;
}
.nav-top-bar > *,
.nav-bottom-bar > * {
  margin: auto!important;
}

/* -------------------- figma ----------------------- */
.nav-bottom-bar {
  height: 55px!important;
  background: #FFFFFF!important;
  box-shadow: 0px -2px 20px rgba(0, 0, 0, 0.2)!important;
}

.nav-top-bar  {
  /*display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 16px;
  gap: 32px;*/

  height: 55px!important;
  background: linear-gradient(90deg, #FF0000 0%, #FF5959 0.01%, #E10404 100%)!important;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25)!important;
}

.top-bar-menu-title {
  /* Menu */

  /*  position: absolute;
    width: 93px;
    height: 16px;
    left: calc(50% - 100%/2 - 135.5px);
    top: 46px;*/

  font-family: 'Muller'!important;
  font-style: normal!important;
  font-weight: 700!important;
  font-size: 24px!important;
  line-height: 16px!important;
  /* identical to box height, or 67% */

  color: #FFFFFF!important;
}

@media screen and (max-width: 500px) {
  .top-bar-menu-title {
    font-size: 17px!important;
    margin-left: 13px!important;
  }
}

.shop-icon-item.first-left,
.shop-icon.first-left,
.top-bar-menu-title.first-left {
  position: absolute!important;
  left: 0!important;
}

.shop-icon-item.second-left,
.top-bar-menu-title.second-left {
  position: absolute!important;
  left: 2em!important;
  padding: 5px!important;
}

.top-bar-menu-title.second-left:first-child {
  position: absolute!important;
  left: 2%!important;
  padding: 5px!important;
}


.back-icon {
  position: absolute;
  left: 1em !important;
  cursor: pointer;
  top: 22px!important;
}

.shop-icon-item.first-right {
  position: absolute!important;
  right: 0!important;
}

.shop-icon-item.second-right {
  position: absolute!important;
  right: 48px!important;
}


@media (max-width: 460px) {
  .shop-icon-item:focus,
  .shop-icon-item,
  .ui.secondary.inverted.menu a.item{
    font-size: x-small;
  }
  .shop-text-item {
    font-size: small;
    line-height: 14px!important;
  }

  .shop-icon {
    width: 2.8em!important;
  }

}

.search-icon {
  color: white;
}

.filter-dropdown {
  background-color: white;
  padding-top: 85px;
  z-index: 1000;
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 55px;
}

.filter-container {
  display: flex;
  flex-direction: row;
}

@media (max-width: 535px) {
  .filter-dropdown {
    gap: 10px!important;
    flex-direction: column;
    align-items: center;
  }
}




.search-form-s {
  width: 600px;
  display: flex;
  align-items: center;
  padding: 0.5em;
  position: fixed;
  top: 0;
  z-index: 10;
  border-radius: 10px !important;
  margin-left: 40px;
  margin-right: 100px;
}

@media (max-width: 720px ) {
  .search-form-s {
    width: 500px;
  }
}

@media (max-width: 620px ) {
  .search-form-s {
    width: 400px;
  }
}

@media (max-width: 520px ) {
  .search-form-s {
    width: 300px;
  }
}

@media (max-width: 420px ) {
  .search-form-s {
    width: 200px;
  }
}

@media (max-width: 320px ) {
  .search-form-s {
    width: 180px;
  }
}

@media (max-width: 378px ) {
  .global_filter_1 {
    margin-left: -170px;
  }

  .country_word {
    margin-left: -283px;
  }

  .global_filter_2 {
    margin-left: 170px;
  }

  .segment_word {
    margin-left: 70px;
  }
}

.search-input {
  width: 100%;
  color: white !important;
}

.ui.form input[type=search] {
  background: none!important;
  border-bottom: 1px solid white!important;
  border-top: transparent;
  border-left: transparent;
  border-right: transparent;
  border-radius: 0!important;
  padding: 3px 0 3px 1px !important;
  color: white!important;
}


.search-field {
  width: 100%;
  background-color: white;
  border: none;
  padding: 0.5em;
  margin-left: 1em;
  flex: 1;
}

.search-button {
  border-radius: 10px!important;
}

.back-icon {
  cursor: pointer;
  margin-right: 1em;
}

.search-container {
  top: 3px;
  left: 35%;
  width: 30%;
  z-index: 1000;
}

.search-container input {
  width: 100%;
  font-size: 12px;
  border: none !important;
  background-color: transparent !important;
  color: #FFFFFF !important;
  outline: none !important;
  border-bottom: 1px solid white !important;
}

.search_exp  {
  width: 100%;
  border: none;
  color: #f9f9f9 !important;
  padding: 10px;
  font-size: 12px;
  outline: none;
  font-family: 'Muller', serif;
}


.filter-modal-content {
  width: 100% !important;
  padding: 30px 20px 30px 15px !important;
  border-radius: 15px !important;
}

.filter-title-text {
  font-size: 20px;
  font-weight: 700;
}

.filter-container {
  margin-top: 5px;
}

.filter-input {
  font-size: 13px !important;
  line-height: 16px;
  background: #EEEEEE !important;
  border-radius: 10px !important;
  border: 0 !important;
}

.filter-modal-btn {
  display: flex !important;
  margin: 20px auto 0 !important;
  font-size: 20px !important;
  justify-content: center;
  align-items: center;
  color: white !important;
  border-radius: 10px !important;
  height: 48px !important;
  background: linear-gradient(to right, #D81524, #871C1C) !important;
}




