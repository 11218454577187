@font-face {
  font-family: 'Montserrat';
  src: url('../../fonts/Montserrat-Regular.ttf');
}
@font-face {
  font-family: 'Muller';
  src: local('Muller Regular'), local('Muller-Regular'),
  url('../../fonts/MullerRegular.woff2') format('woff2'),
  url('../../fonts/MullerRegular.woff') format('woff'),
  url('../../fonts/MullerRegular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}
.checkout-payment-buttons.centered {
  justify-content: center;
  display: flex;
}

.checkout-payment-buttons {
  margin-left: 5% !important;
  width: 90% !important;
}

.checkout-payment-buttons-button.button-confirm {
  width: 64% !important;
  padding: 10px !important;
  border-radius: 10px !important;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2) !important;
  color: #FFFFFF !important;
  background: linear-gradient(to right, #d81524, #871c1c) !important;
  font-size: 16px !important;
  font-family: 'Muller', monospace !important;
}

.checkout-payment-buttons-button.button-back {
  width: 30% !important;
  border-radius: 10px !important;
  background: #A8A8A8 !important;
  font-family: 'Muller', monospace !important;
}
.delivery-details-card{
  margin-top: -10px !important;
  box-shadow:none !important;
}
.checkout-payment-header{
  font-size: 15px !important;
  font-weight: bold !important;
  margin-top: 15px !important;
  margin-bottom: 15px !important;
}
.payment-options-radio {
  font-family: 'Montserrat', monospace !important;
  font-size: 13px !important;
}
.checkout-payment-options{
  padding-bottom: 0 !important;
}
.label-payment-option{
  color: #848484 !important;
  font-size: 10px !important;
  font-family: 'Montserrat', monospace !important;
  line-height: 1rem !important;
}
.label-payment-option-inline {
  background: transparent !important;
}
.label-payment-option {
  width: 100% !important;
}
.payment-option-email input:focus {
  box-shadow: none !important;
  border-color: #2185d0 !important;
}
.payment-option-email {
  border-bottom: 1px solid rgba(34, 36, 38, .15) !important;
}

