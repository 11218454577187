@font-face {
    font-family: 'Muller';
    src: local('Muller Regular'), local('Muller-Regular'),
    url('../../fonts/MullerRegular.woff2') format('woff2'),
    url('../../fonts/MullerRegular.woff') format('woff'),
    url('../../fonts/MullerRegular.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'MullerBold';
    src: url('../../fonts/muller-bold.eot'); /* IE 9 Compatibility Mode */
    src: url('../../fonts/muller-bold.eot?#iefix') format('embedded-opentype'), /* IE < 9 */
    url('../../fonts/muller-bold.woff2') format('woff2'), /* Super Modern Browsers */
    url('../../fonts/muller-bold.woff') format('woff'), /* Firefox >= 3.6, any other modern browser */
    url('../../fonts/muller-bold.ttf') format('truetype'), /* Safari, Android, iOS */
    url('../../fonts/muller-bold.svg#muller-bold') format('svg'); /* Chrome < 4, Legacy iOS */
}

.notif-button {
    display: flex !important;
    border: 0px !important;
    border-radius: 10px !important;
    width: 100%;
    background: none !important;
    padding: 15px 15px 0!important;
    justify-content: space-between !important;
    box-shadow: 0px 0px 12px -6px black !important;
    margin-top: 1.5% !important;
    align-items: start !important;
    margin-bottom: 14px !important;
    flex-direction: column;
    position: relative;
}


.notif-view {
    display: flex!important;
    float: right!important;
    /*margin: auto 0 5px auto !important;*/
    /*margin-top: 5px!important;*/
    margin: 0!important;
    align-items: center!important;
    justify-content: center!important;
    background: none!important;
    padding: 10px !important;
    /*position: absolute;*/
    /*inset: 0 !important;*/
    width: fit-content !important;
    height: 20px;
    gap:5px;
    font-weight: 300 !important;
    font-size: 12px!important;
    color: red !important;
}

.delete-notify-button {
    padding: 0 !important;
    font-size: 12px!important;
    font-weight: 300!important;
    background: none!important;
    color: black!important;
    opacity: 50% !important;
}

.notif-text {
    font-weight: 300;
    line-height: 16px;
    word-spacing: 0px;
    text-align: start;
    font-size: 13px;
    color: black;
}

.notif-text-noviewed {
    font-weight: 600;
    line-height: 16px;
    word-spacing: 0px;
    text-align: start;
    font-size: 13px;
    color: black;
}

.delivered-time {
    font-weight: 300;
    line-height: 16px;
    word-spacing: 0px;
    text-align: start;
    font-size: 11px;
    color: black;
}

.red-circle {
    position: relative;
    background-color: #E10404;
    border-radius: 50%;
    width: 100%;
    max-width: 11px;
    height: 11px;
    bottom: 17px;
    left: 8px;
}

.gray-circle {
    position: relative;
    background-color: #DCDCDC;
    border-radius: 50%;
    width: 100%;
    max-width: 11px;
    height: 11px;
    bottom: 17px;
    left: 8px;
}

.notif-container {
    margin: 0 2% 0;
}

.notif-popup {
    max-width: 800px !important;
    width: 75% !important;
}

.notif-popup-content {
    padding: 15px !important;
}

.notif-popup-button {
    position: relative;
    background: none;
    border: none;
    bottom: 15px;
    left: 15px;
}

.notif-popup-title-text {
    font-family: MullerBold;
    font-weight: 300;
    line-height: 16px;
    word-spacing: 0px;
    text-align: start;
    font-size: 13px;
    color: black;
    width: 100%;
    padding-top: 5px;
}

.notif-popup-content-title {
    display: flex;
    justify-content: space-between;
}

.popup-main-text {
    font-size: 13px;
    margin-top: 10px;
}
