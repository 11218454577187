.bookmark-topbar .bookmark {
    margin: 0 12px!important;
    box-shadow: none!important;
}
.bookmark-topbar .bookmark.loader {
    width: 24px!important;
}
.bookmark.image {
    box-shadow: none!important;
}
.bookmark.loader {
    box-shadow: none!important;
}