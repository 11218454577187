.meeting-view {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.meeting-search {
    margin-top: 1px;
    max-width: 374px !important;
    width: 100% !important;
    border-radius: 10px !important;
    font-size: 10px !important;
    color: #fff !important;
}

.meeting-search div.ui.input {
    width: 100%;
    height: 32px;
    text-align: center;
}

.meeting-search input.prompt {
    border-radius: 10px !important;
    border: none;
    box-shadow: rgba(0, 0, 0, 0.1) 0 0 5px 0, rgba(0, 0, 0, 0.1) 0 0 1px 0 !important;
}

.meeting-search .input > input {
    padding-left: 50px;
}

.meeting-icon {
    background-image: url("../../icons/search-icon.png");
    background-repeat: no-repeat;
    background-position: center;
    left: 10px !important;
}

.meeting-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 374px !important;
    width: 100% !important;
    margin-top: 23px;
}

.meeting-formfield {
    margin-bottom: 15px !important;
    width: 100% !important;
    font-weight: 500 !important;
}

.meeting-formfield .ui.dropdown .menu .active.item {
    font-weight: 500 !important;

}

.meeting-label {
    font-size: 12px !important;
    font-weight: 400 !important;
    margin-bottom: 12px !important;
}

.meeting-dropdown {
     border-radius: 10px !important;
     box-shadow: rgba(0, 0, 0, 0.1) 0 0 5px 0, rgba(0, 0, 0, 0.1) 0 0 1px 0 !important;
     width: 100% !important;
     min-height: 32px !important;
     display: flex !important;
     align-items: center !important;
     justify-content: space-between;
 }

.meeting-text {
    border-radius: 10px !important;
    box-shadow: rgba(0, 0, 0, 0.1) 0 0 5px 0, rgba(0, 0, 0, 0.1) 0 0 1px 0 !important;
    width: 100% !important;
    min-height: 32px !important;
    height: 32px;
    text-align: left !important;
}

.meeting-dropdown.ui.selection.dropdown {
    padding: 9.5px 14px !important;
}


.meeting-btn {
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    width: 200px;
    height: 31px;
    background-image: linear-gradient(to right, #D81524,#871C1C) !important;
    color: white !important;
    border-radius: 10px !important;
    font-size: 14px !important;
    line-height: 16px !important;
    margin-top: 27px;
}

.meeting-btn:hover {
    opacity: 0.7;
}

.meeting-title {
    font-size: 22px;
    text-align: center;
}

.meeting-title-span {
    background-image: linear-gradient(to right,  #D81524 0%, #871C1C 100%);
    background-clip: text;
    color: transparent;
}

.meeting-close {
    position: absolute;
    width: 15px;
    height: 15px;
    top: 0;
    right: 0;
    cursor: pointer;
}

.meeting-close:hover {
    opacity: 0.7;
}
