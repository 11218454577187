.accordion-content{
    text-align: justify!important;

}

.style-accordion-FAQ{
    width: 90%!important;
    margin-left: auto;
    margin-right: auto;


}

.style-accordion-FAQ .title {
    border: none!important;
    border-radius: 10px!important;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 2px 8px!important;
    margin-bottom: 10px !important;

}

.style-accordion-FAQ{
    box-shadow: none!important;
}


