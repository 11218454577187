@font-face {
  font-family: 'Muller';
  src: local('Muller Regular'), local('Muller-Regular'),
  url('../../fonts/MullerRegular.woff2') format('woff2'),
  url('../../fonts/MullerRegular.woff') format('woff'),
  url('../../fonts/MullerRegular.ttf') format('truetype');
  font-style: normal;
}
@font-face {
  font-family: 'Montserrat';
  src: url('../../fonts/Montserrat-Regular.ttf');
}
.order-update-loader {
  position: fixed!important;
  top: 50%!important;
  left: 50%!important;
}
.order-mini-card-header-first-col {
  width: 40%!important;
}
.order-mini-card-header-first-col img {
  max-width: 125px!important;
  width: 100%!important;
}
.order-mini-card-header-second-col {
  width: 60%!important;
}
.order-mini-card-phone{
  color:black!important;
  font-family: 'Montserrat', monospace !important;
  font-size: 13px !important;
}
.order-mini-card-mail{
  color:black!important;
  font-family: 'Montserrat', monospace !important;
  font-size: 13px !important;
}
.order-mini-card-mail,
.order-mini-card-edit-photo {
  float: right;
}
.order-header-fio-col{
  font-size: 13px !important;
  font-family: 'Montserrat', monospace !important;
  line-height: 2 !important;
  font-weight: normal !important;
}
.order-header-name-services{
  font-size: 15px !important;
  font-family: 'Montserrat', monospace !important;
  line-height: 2.5 !important;
  font-weight: normal !important;
  text-align:center;
  margin-bottom: -10px;
}
.grid-order-first-second-col{
  margin-bottom: -20px !important;
}
.button-create-again {
  width: 90%!important;
  margin: 1em 5%!important;
}
.order-mini-card-cancel-button {
  background: transparent!important;
  position: absolute!important;
  right: 5px!important;
  top: 10px!important;
  color: lightgrey!important;
  font-size: 20px!important;
}
.modal-order-badge {
  top: 1em!important;
  position: static;
}
.order-card-header {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 1.5em;
  margin-top: 4px !important;
}
.order-card-header div{
  margin-top: 0.5em;
}
.order-badge {
  margin: 1em 0 0 0;
  max-width: 480px!important;
  max-height: 300px!important;
  min-width: 480px!important;
  min-height: 300px!important;
}
.order-badge .content{
  margin: 0!important;
  padding: 0!important;
}
.order-card{
  box-shadow: none !important;
  width: 90% !important;
  margin-bottom: 0 !important;
}
.react-html5-camera-photo video,
.image-preview.image-preview-fullscreen {
  max-width: 480px;
  margin: 0 auto;
}
.mini-order-action-button-pay{
  border-radius: 10px !important;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2) !important;
  color:#FFFFFF !important;
  background: linear-gradient(to right,#d81524,#871c1c) !important;
  font-size: 16px !important;
  font-family: 'Muller', monospace !important;
}
.mini-order-action-buttons{
  display: flex !important;
  flex-direction: row;
  justify-content: space-between;
  gap:5px;
  width: 100%;
  margin-top: 15px !important;
}
.mini-order-action-button-edit{
  border-radius: 10px !important;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2) !important;
  color:#FFFFFF !important;
  background: #A8A8A8 !important;
  font-size: 16px !important;
  font-family: 'Muller', monospace !important;
  margin-left: 2px !important;
}
.image-preview.image-preview-fullscreen {
  margin-bottom: 5px;
}
.order-card-header .label {
  height: 30px !important;
  width: 163px !important;
  border-radius: 0 0 7px 7px !important;
  font-family: "Muller",monospace !important;
  font-size: 13px !important;
  font-weight: bold !important;
}
.order-card .cart-product .third-column {
  display: none!important;
}
.order-mini-card-country{
  font-family: 'Montserrat', monospace !important;
  font-size: 13px !important;
}
.card-order{
  border-radius: 20px !important;
  width: 100%!important;
  box-shadow: 0 0 0 1px #CACACA !important;
}
.card-order-content{
  border-top:none !important;
}
.order-mini-card-contacts{
  box-shadow: none !important;
  border:none !important;
  display: flex;
  flex-direction: column;
  justify-content: left;
  padding: 0 !important;
  margin-bottom:0 !important;
}
@media (min-width: 802px) {
  .make-photo,
  .modal-order-badge,
  .modal-order-edit-address,
  .modal-expo-location {
    max-width: 800px !important;
    margin-left: calc((100% - 800px) / 2) !important;
  }
}

@media (max-width: 768px) {
  .order-mini-card-contacts {
    display: grid;
  }
  .order-mini-card-mail {
    margin-top: 1em;
  }
}
