@font-face {
    font-family: 'Muller';
    src: local('Muller Regular'), local('Muller-Regular'),
    url('../../fonts/MullerRegular.woff2') format('woff2'),
    url('../../fonts/MullerRegular.woff') format('woff'),
    url('../../fonts/MullerRegular.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'MullerBold';
    src: url('../../fonts/muller-bold.eot'); /* IE 9 Compatibility Mode */
    src: url('../../fonts/muller-bold.eot?#iefix') format('embedded-opentype'), /* IE < 9 */
    url('../../fonts/muller-bold.woff2') format('woff2'), /* Super Modern Browsers */
    url('../../fonts/muller-bold.woff') format('woff'), /* Firefox >= 3.6, any other modern browser */
    url('../../fonts/muller-bold.ttf') format('truetype'), /* Safari, Android, iOS */
    url('../../fonts/muller-bold.svg#muller-bold') format('svg'); /* Chrome < 4, Legacy iOS */
}
.upper-text{
    color: #FF0000;
    font-weight: bold;
    font-size: 24px;
    font-family: 'MullerBold', monospace;
}
.upper-text-container{
    justify-content: center;
    text-align: center;
    margin-bottom: 40px;
}
.FIO{
    justify-content: center;
    text-align: center;
    font-size: 24px;
    font-family: 'MullerBold', monospace;
    line-height: 1.4;
    margin-bottom: 40px;
}
.organization{
    justify-content: center;
    text-align: center;
    font-size: 24px;
    font-family: 'Muller', monospace;
    line-height: 1.4;
}
.code{
    color: #C70303;
    font-weight: bold;
    font-size: 20px;
    font-family: 'MullerBold', monospace;
    justify-content: center;
    text-align: center;
}
.code-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    line-height: 2;
    align-items: center;
    text-align: center;
    margin-top: 50px;
}
.buy-ticket-button{
    border-radius:0.9rem;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    color:#FFFFFF;
    background: linear-gradient(to right,#d81524,#871c1c);
    width: 294px;
    height: 54px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border: 0;
}
.buy-ticket-button span {
    font-size: 24px;
    font-family: 'MullerBold', monospace;
}
.no-ticket-page{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
    margin: auto;
    height: 85vh;
}
.no-ticket-message span{
    display: flex;
    text-align: center;
    flex-direction: column;
    margin-left: 20px;
    max-width: 90%;
}
.no-ticket-message br {
    margin-bottom: 20px;
    content:'';
    display: block;
}
.button-container{
    display: flex;
    justify-content: center;
    align-content: center;
    margin-top: 200px;
}