@font-face {
    font-family: 'Muller-Bold';
    src: url('../../fonts/muller-bold.eot'); /* IE 9 Compatibility Mode */
    src: url('../../fonts/muller-bold.eot?#iefix') format('embedded-opentype'), /* IE < 9 */
    url('../../fonts/muller-bold.woff2') format('woff2'), /* Super Modern Browsers */
    url('../../fonts/muller-bold.woff') format('woff'), /* Firefox >= 3.6, any other modern browser */
    url('../../fonts/muller-bold.ttf') format('truetype'); /* Safari, Android, iOS */
    font-style: normal;
    font-weight: bold;
}

.fimg {
    position: relative;
    /* Нужно, чтобы абсолютно позиционированные дочерние элементы позиционировались относительно края родителя. */
    display: inline-block;
}

.congress-icon {
    object-fit: cover;
    border-radius: 10px;
    height: 164px;
    width: 100%;
}

.fimg {
    position: relative;
    width: 100%;
    height: 164px;
    border-radius: 10px;
    background: #f74040;
    display: block;
    flex-direction: column;
}

.text-for-tickets {
    color: white;
    text-align: left;
    font-family: 'Muller-Bold', monospace;
    font-size: 24px;
    position: absolute;
    top: 17px;
    left: 16px;
}

.arrow-icon {
    position: absolute;
    top: 75%;
    left: 67%;
}

.block-container {
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-right: 1%;
    margin-left: 1%;
}

