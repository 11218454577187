.edit-view {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.edit-avatar {
    width: 78px;
    height: 78px;
    border-radius: 50%;
}

.edit-icon-container {
    position: relative;
    width: 78px;
    height: 78px;
    border-radius: 50%;
    background-color: #D9D9D9;
    margin-bottom: 25px;
    cursor: pointer;
}

.edit-icon-container:hover {
    opacity: 0.3;
}

.edit-icon-1 {
    position: absolute;
    width: 57px;
    height: 57px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: 0.5;
}

.edit-icon-2 {
    position: absolute;
    width: 30px;
    height: 30px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: -999;
}

.edit-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.edit-field {
    width: 90%;
    margin-bottom: 23px !important;
}

.edit-container {
    margin-bottom: 10px;
    max-width: 720px;
}

.edit-input {
    display: flex;
    align-items: center;
    font-size: 13px !important;
    min-height: 15px !important;
    width: 100% !important;
    line-height: 16px;
    border-radius: 0 !important;
    padding: 0 !important;
    border: none !important;
    border-bottom: 1px solid #D9D9D9 !important;
    box-shadow: none !important;

}

.edit-input div.visible.menu.transition {
    width: 100% !important;
}
.react-datepicker-wrapper {
    width: 100% !important;
}

.edit-input input.search {
    border-radius: 0 !important;
    padding: 0 !important;
}



div.ui.fluid.search.selection.dropdown.edit-input {
    min-height: 15px !important;
}

.edit-input:focus {
    border-bottom: 1px solid #2185D0 !important;
}

.edit-input input[type="text"]:focus {
    border: none !important;
    border-bottom: 1px solid #2185D0 !important;
}

#edit-input i.dropdown.icon {
    top: 0 !important;
    right: 0 !important;
}

.doc_kind {
    height: 26.67px !important;
}



.doc_kind input.search {
    height: 26.67px !important;
}

.edit-input input[type="text"].search:focus {
    border: none !important;
    border-bottom: 1px solid #2185D0 !important;
}

#input-lastname {
    margin: 0 !important;
}

.edit-input-phone input#masked_phone,
.edit-input-phone .ui.selection.dropdown {
    border: none !important;
    padding-bottom: 0;
}

.edit-input-phone .ui.selection.dropdown {
    height: 13px !important;
    padding-left: 0;
    padding-right: 0;
}

.edit-input-phone input.search {
    padding: 0 !important;
    text-align: center;
}

.edit-input-phone div.divider.text,
.edit-input-phone input.search {
    width: 30px !important;
}

.edit-input-phone #dropdown_country {
    width: 45px !important;
}

.edit-input-phone .flag {
    margin: 0 !important;
    margin-right: 5px !important;
}

.edit-input-phone .icon {
    padding-left: 0 !important;
}


.edit-label {
    font-size: 13px;
    line-height: 16px;
    font-weight: 700;
    margin-bottom: 9px;
}

.edit-submit {
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    width: 183px;
    height: 39px;
    background-image: linear-gradient(to right, #D81524,#871C1C) !important;
    color: white !important;
    border-radius: 10px !important;
}

.edit-error {
    color: #D81524;
    font-size: 13px;
    line-height: 16px;
    font-weight: 700;
}

.edit-checkbox-container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.edit-checkbox-container-ticket {
    display: flex;
    justify-content: center;
    margin-left: 10%;
    margin-top: 3%;
    align-items: center;
}

.edit-checkbox {
    margin-right: 10px;
}

.edit-checkbox-text {
    font-size: 13px;
    line-height: 16px;
}

@media screen and (max-width: 768px) {
    .doc_kind {
        min-height: 31px !important;
    }

    .doc_kind input.search {
        min-height: 31px !important;
    }
}

@media screen and (max-width: 500px) {
    .doc_kind {
        height: 41px !important;
    }



    .doc_kind input.search {
        height: 41px !important;
    }
}

@media screen and (max-width: 340px) {
    .doc_kind {
        height: 55px !important;
    }


    .doc_kind input.search {
        height: 55px !important;
    }
}
